import React, { PureComponent } from 'react';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../container/SubscriptionContainer.scss';
import { hideModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { connect } from 'react-redux';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { nonAirtelPageLoadEvent, swicthToAirtelClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import lodashGet from 'lodash/get';
import { characterToUpperCase } from '../../../utilities/CommonUtil';
import { paginatedSubscriptionLayoutFetchAction } from '@airtel-feature/layout/actions/LayoutActions';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { osVersion } from 'react-device-detect';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

const IMAGE_SETTINGS = {
    FLOATING_LARGE: {
        1000: 390,
        500: 275,
        320: 400,
    },
};
class NonAirtelComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCursorOnRail: false,
            indexOfRail: 0,
            scrollIntervalId: 0,
            dragScrollTimerId: 0,
            isRailRendered: false,
        };
        this.deviceUtil = props.deviceUtil;

        this.carouselElementRef = React.createRef();
    }

    componentDidMount() {
        this.pageLoadEvent();
        const { railAutoScrollInterval, layoutFetchActionDispatch } = this.props;

        layoutFetchActionDispatch({
            pageId: 'purchaseSubscriptionBanner',
            cpId: 'XSTREAMPREMIUM',
        });
        setTimeout(() => {
            if (this.carouselElementRef.current) {
                this.carouselElementRef.current.addEventListener('touchmove', () => {
                    this.setState({ isCursorOnRail: true });
                    window.clearTimeout(this.state.dragScrollTimerId);
                });

                this.carouselElementRef.current.addEventListener('touchend', () => {
                    const dragScrollTimerId = setTimeout(() => {
                        this.setState({ isCursorOnRail: false });
                        this.next(false);
                        this.setState({ dragScrollTimerId });
                    }, 1500);
                });
            }
            const scrollIntervalId = setInterval(() => {
                if (!this.state.isCursorOnRail) {
                    this.next(false);
                }
            }, railAutoScrollInterval);
            this.setState({ scrollIntervalId });
        }, 1500);
    }

    componentWillUnmount() {
        clearInterval(this.state.scrollIntervalId);
        clearTimeout(this.state.dragScrollTimerId);
        if (this.carouselElementRef.current) {
            this.carouselElementRef.current.removeEventListener('touchmove', () => {});
            this.carouselElementRef.current.removeEventListener('touchend', () => {});
        }
    }

    pageLoadEvent() {
        const { circle, sid ,userConfig,uid} = this.props;

        const offerSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            ingressIntent: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            pageName: ANALYTICS_ASSETS.SWITCH_TO_AIRTEL,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        nonAirtelPageLoadEvent(offerSelectionAnalyticsMeta);
    }

    checkIfIosMobility = () => {
        const os = browserStore.get(LOCAL_STORE_KEYS.OS);
        if (this.deviceUtil.isIOS() && this.deviceUtil.isMobile() && parseFloat(osVersion).toFixed(2)  >= parseFloat(15.4).toFixed(2)) {
            return true;
        } else if (this.deviceUtil.isMobile() && os && os.toLowerCase() !== 'android' && parseFloat(osVersion).toFixed(2)  >= parseFloat(15.4).toFixed(2)) {
            return true;
        } else if (os && os.toLowerCase() === 'ios' && parseFloat(osVersion).toFixed(2)  >= parseFloat(15.4).toFixed(2)) {
            return true;
        }
        return false;
    }

    next= (onPress) => {
        this.setState({ isRailRendered: true });
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            const { clientWidth } = this.carouselElementRef.current;
            let widthToAdd = onPress ? clientWidth / 4 : this.checkIfIosMobility() ? 26 : 3;
            let widthToSub = 0;
            let widthForReset = 0;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft += widthToAdd;
                widthToSub = images[images.length - 1]?.offsetWidth + images[images.length - 2]?.offsetWidth + images[images.length - 3]?.offsetWidth + 30;
                for (const image of images) {
                    widthForReset += (image.offsetWidth + 10);
                }
                widthForReset -= widthToSub;
            }
            if (this.carouselElementRef.current.scrollLeft >= widthForReset) {
                this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
                const widthOfFirstImage = images[0].offsetWidth + 10;
                widthToAdd = this.carouselElementRef.current.scrollLeft - widthForReset + widthOfFirstImage + 50;
                this.carouselElementRef.current.scrollLeft = widthToAdd;
                this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
                if (!this.checkIfIosMobility()) {
                    this.next(onPress);
                }
            }
        }
    }

    previous = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            const images = this.carouselElementRef.current.children;
            this.carouselElementRef.current.scrollLeft -= clientWidth / 4;
            const widthOfFirstImage = images[0].offsetWidth;
            if (this.carouselElementRef.current.scrollLeft <= widthOfFirstImage) {
                let widthForReset = 0;
                const widthToSub = widthOfFirstImage - this.carouselElementRef.current.scrollLeft;
                for (const image of images) {
                    widthForReset += (image.offsetWidth + 10);
                }
                const widthOfLastThreeImages = images[images.length - 1].offsetWidth + images[images.length - 2].offsetWidth + images[images.length - 3].offsetWidth + 30;
                this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
                this.carouselElementRef.current.scrollLeft = widthForReset - widthToSub - widthOfLastThreeImages + 20;
                this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
                this.previous();
            }
        }
    }

    switchToAirtel = (_) => {
        const { circle, sid,userConfig,uid } = this.props;

        const offerSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            ingressIntent: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            pageName: ANALYTICS_ASSETS.SWITCH_TO_AIRTEL,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        swicthToAirtelClickEvent(offerSelectionAnalyticsMeta);

        window.open('https://www.airtel.in/mnp/', '_self');
    }

    closePopup = (_) => {
        const { hideModalComponentActionDispatch } = this.props;
        hideModalComponentActionDispatch();
    }

    filterbanners = purchaseSubscriptionBanner => purchaseSubscriptionBanner.map(banner => banner.images.RECTANGULAR || banner.images.PORTRAIT_HD || banner.images.PORTRAIT)

    railRendered = () => {
        const images = this.carouselElementRef.current.children;
        const { isRailRendered } = this.state;
        if (images && images.length > 0 && !isRailRendered) {
            this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
            this.carouselElementRef.current.scrollLeft = images[0].offsetWidth + 10;
            this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
        }
    }

    render() {
        let { planConfig, purchaseSubscriptionBanner, title, payload: { hideCrossBtn = false } = {} } = this.props;
        const { isCursorOnRail } = this.state;
        const { browsePlans = [] } = planConfig;
        let desc = '';
        let premiumPlanCpId = browsePlans.find(browsePlan => browsePlan.meta && browsePlan.meta.offerType === 'PREMIUM');
        if (premiumPlanCpId && premiumPlanCpId.cpId) {
            desc = premiumPlanCpId.description;
            premiumPlanCpId = premiumPlanCpId.cpId;
        }
        const layoutData = lodashGet(purchaseSubscriptionBanner, `purchaseSubscriptionBanner:${premiumPlanCpId}.layoutPackages[0].contents[0].details.content`, []);
        purchaseSubscriptionBanner = this.filterbanners(layoutData);
        const initialImages = purchaseSubscriptionBanner.slice(0, 2);
        const lastImage = purchaseSubscriptionBanner.slice().reverse().slice(0, 1);
        purchaseSubscriptionBanner = [
            ...lastImage,
            ...purchaseSubscriptionBanner,
            ...initialImages,
        ];
        return (
            <div className="non-airtel-popup">
                <button
                    className={`non-airtel-cross ${window.location.pathname.includes(ROUTE_PATHS.DEEPLINK_LANDING_PAGE) || hideCrossBtn ? 'd-none' : ''}`}
                    onClick={this.closePopup}
                >
                    <ThumborImage
                        src="/static/close.svg"
                    />
                </button>
                <p className="non-airtel-heading">
Switch to Airtel to get
                    {' '}
                    {characterToUpperCase(title) || 'Xstream Premium'}
                </p>
                <p className="non-airtel-text">{desc}</p>
                <div
                    className="non-airtel-rail-wrapper"
                    onMouseOver={() => this.setState({ isCursorOnRail: true })}
                    onMouseLeave={() => this.setState({ isCursorOnRail: false })}
                >

                    { isCursorOnRail && (
                        <div className="non-airtel-gredient left">
                            <button
                                className="non-airtel-btn"
                                onClick={this.previous}
                            >
                                <ThumborImage
                                    src="/static/left-chev.svg"
                                />
                            </button>
                        </div>
                    )}

                    <div
                        className="non-airtel-rail"
                        style={{ height: 230 }}
                        ref={this.carouselElementRef}
                        onLoad={this.railRendered}
                    >
                        {
                            purchaseSubscriptionBanner && purchaseSubscriptionBanner.length > 0
                            && purchaseSubscriptionBanner.map((imgurl, index) => (
                                <ThumborImage
                                    key={index}
                                    style={{ height: 230 }}
                                    src={imgurl}
                                    imageSettings={IMAGE_SETTINGS.FLOATING_LARGE}

                                />
                            ))
                        }

                        <div className="non-airtel-cards">
                            <ThumborImage
                                src="/static/non-airtel-dummy.svg"
                            />
                        </div>

                    </div>
                    { isCursorOnRail && (
                        <div className="non-airtel-gredient right">
                            <button
                                className="non-airtel-btn"
                                onClick={() => this.next(true)}
                            >
                                <ThumborImage
                                    src="/static/left-chev.svg"
                                />
                            </button>
                        </div>
                    )}
                </div>
                <button
                    className="non-airtel-switch-btn common-white-btn"
                    onClick={this.switchToAirtel}
                >
                    Switch to Airtel
                </button>
            </div>

        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        planConfig,
        layoutDetails,
        appConfig,
        modalConfig,
        userConfig
    } = state;
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const railAutoScrollInterval = lodashGet(appConfig, 'railAutoScrollInterval', 50);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const cpId = lodashGet(modalConfig, 'componentProps.payload.cpId');
    const title = lodashGet(cpDetailsById[cpId], 'title', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        purchaseSubscriptionBanner: layoutDetails,
        sid,
        circle,
        planConfig,
        railAutoScrollInterval,
        title,
        userConfig,
        uid
    };
};
export default connect(mapStateToProps, {
    hideModalComponentActionDispatch: hideModalComponentAction,
    layoutFetchActionDispatch: paginatedSubscriptionLayoutFetchAction,
})(withDeviceUtil(withStyles(styles)(NonAirtelComponent)));
